import React from 'react';
import Logo from '../../components/Logo/Logo';
import { AuthenticationLayoutStyleWrapper, LogoWrapper, Background } from './AuthenticationLayout.style';

import background from './oqio-background.jpg';

/**
 * Functional component for the authentication layout.
 * Provides a styled layout for authentication pages including a logo and links to legal documents.
 * @property {Object} config - configuration object containing tenant details and links to legal documents.
 * @property {React.ReactNode} children - the child components to be displayed within the layout.
 */
const AuthenticationLayout = ({ config, children }) => {
  const { tenant_logo, tenant_name, imprint, terms_of_use, privacy_statement } = config;

  return (
    <AuthenticationLayoutStyleWrapper>
      <LogoWrapper>
        <Logo agencyLogo={tenant_logo?.url} agencyName={tenant_name} light />
      </LogoWrapper>
      {children}
      <div className="terms-menu">
        <a
          className={imprint ? 'oqio-link valid-link' : 'oqio-link'}
          href={imprint?.url ? imprint.url : '#'}
          target={imprint ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          Impressum
        </a>
        <a
          className={terms_of_use ? 'oqio-link valid-link' : 'oqio-link'}
          href={terms_of_use?.url ? terms_of_use.url : '#'}
          target={terms_of_use ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          Nutzungsbedingungen
        </a>
        <a
          className={privacy_statement ? 'oqio-link valid-link' : 'oqio-link'}
          href={privacy_statement?.url ? privacy_statement.url : '#'}
          target={privacy_statement ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          Datenschutzinformationen
        </a>
      </div>
      <Background src={background} alt="building" />
    </AuthenticationLayoutStyleWrapper>
  );
};

export default AuthenticationLayout;
