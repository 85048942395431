import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Button } from '../ui';
import { IconDownload } from '../ui/Icons';
import renderNotification from '../../helpers/notifications/renderNotification';
import importActions from '../../redux/import/actions';
import { ATTACHMENT_MAX_SIZES } from '../../consts/consts';
import {
  ImportTemplateButtonWrapper,
  ImportUploaderDropzoneWrapper,
  ImportUploaderSectionWrapper,
  ImportUploaderText,
  ImportUploaderHighlightedText,
  ImportUploaderTextWrapper
} from '../ui/Layout/ImportLayout';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';

/**
 * This component is the first step of the import process. It is used for uploading the import file.
 * @param {function} onFileUpload
 * @param {File} importFile
 * @param {function} uploadImportFile - this function is used to upload the import file to the frontend (save it in redux).
 * @param {function} getImportTemplate
 * @param {function} checkImportFile - this function is used to send the import file to the backend for checking.
 */
const ImportUploaderSectionComponent = ({
  onFileUpload,
  importFile,
  uploadImportFile,
  getImportTemplate,
  checkImportFile
}) => {
  useEffect(() => {
    if (importFile) {
      handleCheckImportFile(importFile);
    }
  }, [importFile]);

  const handleCheckImportFile = (file) => {
    checkImportFile(file);
    onFileUpload();
  };

  const handleGetImportTemplate = () => {
    getImportTemplate();
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    uploadImportFile(file);
  };

  const onDropRejected = (rejectedFiles) => {
    const errorCode = rejectedFiles[0].errors[0].code;
    switch (errorCode) {
      case 'file-invalid-type': {
        const errorMessage = NOTIFICATION_MESSAGES.message_attachment_invalid_file_type.replace(
          '$FILE_NAME',
          rejectedFiles[0].file.name
        );
        renderNotification({ type: 'error', message: errorMessage });
        break;
      }
      default:
        renderNotification({ type: 'error', message: 'Ein unbekannter Fehler ist aufgetreten!' });
    }
  };

  const options = {
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: ATTACHMENT_MAX_SIZES.byte,
    disabled: false,
    accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }
  };

  const { getRootProps, getInputProps } = useDropzone(options);

  const renderDropzone = () => (
    <ImportUploaderDropzoneWrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <ImportUploaderTextWrapper>
        <ImportUploaderText>Datei (*.xlsx) auf diese Fläche ziehen oder </ImportUploaderText>
        <ImportUploaderHighlightedText>Datei auswählen</ImportUploaderHighlightedText>
      </ImportUploaderTextWrapper>
    </ImportUploaderDropzoneWrapper>
  );

  const renderBottomSection = () => (
    <ImportTemplateButtonWrapper>
      <Button type="text" className="btn-low-height" icon={<IconDownload />} onClick={handleGetImportTemplate}>
        Excel-Vorlage für den Import herunterladen
      </Button>
    </ImportTemplateButtonWrapper>
  );

  return (
    <ImportUploaderSectionWrapper>
      {renderDropzone()}
      {renderBottomSection()}
    </ImportUploaderSectionWrapper>
  );
};

const { getImportTemplate, uploadImportFile, checkImportFile } = importActions;

const mapDispatchToProps = {
  getImportTemplate,
  uploadImportFile,
  checkImportFile
};

const mapStateToProps = (state) => ({
  importFile: state.Import.get('importFile')
});

ImportUploaderSectionComponent.displayName = 'ImportUploaderSection';

export const ImportUploaderSection = connect(mapStateToProps, mapDispatchToProps)(ImportUploaderSectionComponent);
