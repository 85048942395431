import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import ConfigProvider from 'antd/es/config-provider';
import deDE from 'antd/locale/de_DE';
import { THEME_CONFIG } from './config/theme';
import { initializeSentry } from './config/sentry';
import PublicRoutes from './router';
import { store } from './redux/store';
import { ErrorPage } from './components/ErrorPage/ErrorPage';
import { ERROR_TYPES } from './consts/consts';
import 'dayjs/locale/de';

/* Initialize Sentry for error tracking */
initializeSentry();

/**
 * Main application component.
 * Wraps the application in necessary providers such as Redux and Sentry,
 * and sets up the configuration for Ant Design.
 */
const App = () => (
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={() => <ErrorPage errorType={ERROR_TYPES.ERROR_TECHNICAL_PROBLEM} />}>
      <ConfigProvider locale={deDE} theme={THEME_CONFIG}>
        <PublicRoutes />
      </ConfigProvider>
    </Sentry.ErrorBoundary>
  </Provider>
);

export default App;
