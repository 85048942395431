import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadBuildingToPolicies = async (params) =>
  axios
    .get(apiUrls.buildingToPoliciesBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onCreateBuildingToPolicies = async (buildingToPolicies) =>
  axios
    .post(apiUrls.buildingToPoliciesBaseUrl, buildingToPolicies)
    .then((res) => res)
    .catch((error) => error);

const onDeleteManyBuildingToPolicies = async (params) =>
  axios
    .post(`${apiUrls.buildingToPoliciesBaseUrl}/delete-many`, params)
    .then((res) => res)
    .catch((error) => error);

export { onLoadBuildingToPolicies, onCreateBuildingToPolicies, onDeleteManyBuildingToPolicies };
