import { call, put, all, takeLatest } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { getFileNameFromApiResponseHeader } from '../../helpers/utility';
import actions from './actions';
import { onImportCall, onGetImportTemplate } from './apiCalls';

export function* getImportTemplateSaga() {
  try {
    const response = yield call(onGetImportTemplate);
    if (response.status === 200) {
      const filename = getFileNameFromApiResponseHeader(response.headers, '.xlsx');

      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        window.navigator.maSaverBlob(blob, filename);
      } else {
        const blob = new File([response.data], filename, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        FileSaver.saveAs(blob);
      }
      yield put(actions.getImportTemplateSuccess());
    } else {
      yield put(actions.getImportTemplateError());
    }
  } catch (error) {
    yield put(actions.getImportTemplateError());
  }
}

export function* checkImportFileSaga({ file }) {
  try {
    const response = yield call(onImportCall, file, {});
    if (response.status === 200) {
      yield put(actions.checkImportFileSuccess(response.data));
    } else {
      yield put(actions.checkImportFileError());
    }
  } catch (error) {
    yield put(actions.checkImportFileError());
  }
}

export function* executeImportSaga({ file }) {
  try {
    /* 
    We want to first show the user the import preview and only after he confirms it to execute the import.
    At the same time we use the same endpoint for both actions, so we need to pass an additional parameter that tells the backent 
    if it should execute the import or just return the checked table data for preview. 
    */
    const params = {
      sandbox_mode: false
    };
    const response = yield call(onImportCall, file, params);
    if (response.status === 200) {
      yield put(actions.executeImportSuccess());
    } else {
      yield put(actions.executeImportError());
    }
  } catch (error) {
    yield put(actions.executeImportError());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.EXECUTE_IMPORT, executeImportSaga),
    takeLatest(actions.CHECK_IMPORT_FILE, checkImportFileSaga),
    takeLatest(actions.GET_IMPORT_TEMPLATE, getImportTemplateSaga)
  ]);
}
