import React from 'react';
import AntTabs from 'antd/es/tabs';
import styled from 'styled-components';

const StyledTabs = styled(AntTabs)`
  overflow: auto;

  .ant-tabs-nav {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--greyscale-blackish);
      }
    }
  }

  .ant-tabs-content-holder {
    overflow: auto;
  }
`;

export const Tabs = (props) => <StyledTabs {...props} />;
