export const deviceSize = {
  mobileSmall: '360px',
  mobile: '800px',
  tablet: '900px',
  splitScreen: '1000px',
  laptop: '1024px',
  laptopM: '1280px',
  laptopL: '1440px'
};

export const deviceSizeNumber = {
  mobileSmall: 360,
  mobile: 800,
  tablet: 900,
  splitScreen: 1000,
  laptop: 1024,
  laptopM: 1280,
  laptopL: 1440
};

export const windowMinWidth = {
  mobileSmall: `(min-width: ${deviceSize.mobileSmall})`,
  mobile: `(min-width: ${deviceSize.mobile})`,
  tablet: `(min-width: ${deviceSize.tablet})`,
  splitScreen: `(min-width: ${deviceSize.splitScreen})`,
  laptop: `(min-width: ${deviceSize.laptop})`,
  laptopM: `(min-width: ${deviceSize.laptopM})`,
  laptopL: `(min-width: ${deviceSize.laptopL})`
};

export const windowMaxWidth = {
  mobileSmall: `(max-width: ${deviceSize.mobileSmall})`,
  mobile: `(max-width: ${deviceSize.mobile})`,
  tablet: `(max-width: ${deviceSize.tablet})`,
  splitScreen: `(max-width: ${deviceSize.splitScreen})`,
  laptop: `(max-width: ${deviceSize.laptop})`,
  laptopM: `(max-width: ${deviceSize.laptopM})`,
  laptopL: `(max-width: ${deviceSize.laptopL})`
};
