import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onImportCall = async (file, params) => {
  const formData = new FormData();
  formData.append('excel_file', file);

  try {
    const response = await axios
      .post(`${apiUrls.importBaseUrl}/buildings-and-policies`, formData, { params })
      .then((res) => res)
      .catch((error) => error);

    return response;
  } catch (error) {
    return error;
  }
};

const onGetImportTemplate = async () =>
  axios
    .get(`${apiUrls.importBaseUrl}/buildings-and-policies-template`, { responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((error) => error);

export { onImportCall, onGetImportTemplate };
