const actions = {
  CREATE_POLICY: 'CREATE_POLICY',
  CREATE_POLICY_SUCCESS: 'CREATE_POLICY_SUCCESS',
  CREATE_POLICY_ERROR: 'CREATE_POLICY_ERROR',
  LOAD_POLICIES_WITHOUT_PAGINATION: 'LOAD_POLICIES_WITHOUT_PAGINATION',
  LOAD_POLICIES_WITHOUT_PAGINATION_SUCCESS: 'LOAD_POLICIES_WITHOUT_PAGINATION_SUCCESS',
  LOAD_POLICIES_WITHOUT_PAGINATION_ERROR: 'LOAD_POLICIES_WITHOUT_PAGINATION_ERROR',
  LOAD_POLICIES: 'LOAD_POLICIES',
  LOAD_POLICIES_SUCCESS: 'LOAD_POLICIES_SUCCESS',
  LOAD_POLICIES_ERROR: 'LOAD_POLICIES_ERROR',
  LOAD_POLICY: 'LOAD_POLICY',
  LOAD_POLICY_SUCCESS: 'LOAD_POLICY_SUCCESS',
  LOAD_POLICY_ERROR: 'LOAD_POLICY_ERROR',
  GET_POLICY_ORGANIZATIONS_WITH_ACCESS: 'GET_POLICY_ORGANIZATIONS_WITH_ACCESS',
  GET_POLICY_ORGANIZATIONS_WITH_ACCESS_SUCCESS: 'GET_POLICY_ORGANIZATIONS_WITH_ACCESS_SUCCESS',
  GET_POLICY_ORGANIZATIONS_WITH_ACCESS_ERROR: 'GET_POLICY_ORGANIZATIONS_WITH_ACCESS_ERROR',
  CLEAR_SINGLE_POLICY: 'CLEAR_SINGLE_POLICY',
  LOAD_POLICY_AUTH: 'LOAD_POLICY_AUTH',
  LOAD_POLICY_AUTH_SUCCESS: 'LOAD_POLICY_AUTH_SUCCESS',
  LOAD_POLICY_AUTH_ERROR: 'LOAD_POLICY_AUTH_ERROR',
  UPDATE_POLICY: 'UPDATE_POLICY',
  UPDATE_POLICY_SUCCESS: 'UPDATE_POLICY_SUCCESS',
  UPDATE_POLICY_ERROR: 'UPDATE_POLICY_ERROR',
  GET_CUSTOM_VALUES: 'GET_CUSTOM_VALUES',
  GET_CUSTOM_VALUES_SUCCESS: 'GET_CUSTOM_VALUES_SUCCESS',
  GET_CUSTOM_VALUES_ERROR: 'GET_CUSTOM_VALUES_ERROR',
  CLEAR_POLICY_ERRORS: 'CLEAR_POLICY_ERRORS',
  DOWNLOAD_IMPORT_TEMPLATE: 'DOWNLOAD_IMPORT_TEMPLATE',
  DOWNLOAD_IMPORT_TEMPLATE_SUCCESS: 'DOWNLOAD_IMPORT_TEMPLATE_SUCCESS',
  DOWNLOAD_IMPORT_TEMPLATE_ERROR: 'DOWNLOAD_IMPORT_TEMPLATE_ERROR',
  IMPORT_POLICIES_FILE: 'IMPORT_POLICIES_FILE',
  IMPORT_POLICIES_FILE_SUCCESS: 'IMPORT_POLICIES_FILE_SUCCESS',
  IMPORT_POLICIES_FILE_ERROR: 'IMPORT_POLICIES_FILE_ERROR',
  IMPORT_POLICIES: 'IMPORT_POLICIES',
  IMPORT_POLICIES_SUCCESS: 'IMPORT_POLICIES_SUCCESS',
  IMPORT_CREATE_POLICIES_SUCCESS: 'IMPORT_CREATE_POLICIES_SUCCESS',
  IMPORT_CREATE_POLICIES_ERROR: 'IMPORT_CREATE_POLICIES_ERROR',
  IMPORT_UPDATE_POLICIES_SUCCESS: 'IMPORT_UPDATE_POLICIES_SUCCESS',
  IMPORT_UPDATE_POLICIES_ERROR: 'IMPORT_UPDATE_POLICIES_ERROR',
  IMPORT_POLICIES_ERROR: 'IMPORT_POLICIES_ERROR',
  CLEAR_IMPORT_POLICIES_ERRORS: 'CLEAR_IMPORT_POLICIES_ERRORS',
  CLEAR_IMPORT_POLICIES_FILE_ERRORS: 'CLEAR_IMPORT_POLICIES_FILE_ERRORS',
  IMPORT_CREATE_POLICIES: 'IMPORT_CREATE_POLICIES',
  IMPORT_UPDATE_POLICIES: 'IMPORT_UPDATE_POLICIES',
  EXPORT_POLICIES: 'EXPORT_POLICIES',
  EXPORT_POLICIES_SUCCESS: 'EXPORT_POLICIES_SUCCESS',
  EXPORT_POLICIES_ERROR: 'EXPORT_POLICIES_ERROR',
  BULK_ASSIGN_INSURED_RISKS: 'BULK_ASSIGN_INSURED_RISKS',
  BULK_ASSIGN_INSURED_RISKS_SUCCESS: 'BULK_ASSIGN_INSURED_RISKS_SUCCESS',
  BULK_ASSIGN_INSURED_RISKS_ERROR: 'BULK_ASSIGN_INSURED_RISKS_ERROR',
  BULK_UNASSIGN_INSURED_RISKS: 'BULK_UNASSIGN_INSURED_RISKS',
  BULK_UNASSIGN_INSURED_RISKS_SUCCESS: 'BULK_UNASSIGN_INSURED_RISKS_SUCCESS',
  BULK_UNASSIGN_INSURED_RISKS_ERROR: 'BULK_UNASSIGN_INSURED_RISKS_ERROR',
  RESET_POLICIES: 'RESET_POLICIES',
  RESET_ALL_POLICIES: 'RESET_ALL_POLICIES',
  DELETE_POLICIES: 'DELETE_POLICIES',
  DELETE_POLICIES_SUCCESS: 'DELETE_POLICIES_SUCCES',
  DELETE_POLICIES_ERROR: 'DELETE_POLICIES_ERROR',
  GET_CONNECTED_BUILDINGS: 'GET_CONNECTED_BUILDINGS',
  GET_CONNECTED_BUILLDINGS_SUCCESS: 'GET_CONNECTED_BUILLDINGS_SUCCESS',
  GET_CONNECTED_BUILLDINGS_ERROR: 'GET_CONNECTED_BUILLDINGS_ERROR',
  GET_POLICIES_INSURED_RISK_TYPES: 'GET_POLICIES_INSURED_RISK_TYPES',
  GET_POLICIES_INSURED_RISK_TYPES_SUCCESS: 'GET_POLICIES_INSURED_RISK_TYPES_SUCCESS',
  GET_POLICIES_INSURED_RISK_TYPES_ERROR: 'GET_POLICIES_INSURED_RISK_TYPES_ERROR',
  GET_POLICIES_INSURED_RISKS: 'GET_POLICIES_INSURED_RISKS',
  GET_POLICIES_INSURED_RISKS_SUCCESS: 'GET_POLICIES_INSURED_RISKS_SUCCESS',
  GET_POLICIES_INSURED_RISKS_ERROR: 'GET_POLICIES_INSURED_RISKS_ERROR',
  GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS: 'GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS',
  GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS: 'GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS',
  GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR: 'GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR',
  SPREAD_SINGLE_POLICY_INTO_POLICIES: 'SPREAD_SINGLE_POLICY_INTO_POLICIES',
  SET_LAST_VISITED_POLICY_ID: 'SET_LAST_VISITED_POLICY_ID',
  RESET_LAST_VISITED_POLICY_ID: 'RESET_LAST_VISITED_POLICY_ID',
  SET_INSURANCE_ORGANIZATION_LOGOS: 'SET_INSURANCE_ORGANIZATION_LOGOS',

  createPolicy: (policies, insuredRisksToAdd, authorizationsToAdd, linkedBuildings, callback, navigate) => ({
    type: actions.CREATE_POLICY,
    policies,
    insuredRisksToAdd,
    authorizationsToAdd,
    linkedBuildings,
    callback,
    navigate
  }),
  createPolicySuccess: (policy, insuredRisksToAdd, authorizationsToAdd, linkedBuildings) => ({
    type: actions.CREATE_POLICY_SUCCESS,
    policy,
    insuredRisksToAdd,
    authorizationsToAdd,
    linkedBuildings
  }),
  createPolicyError: () => ({
    type: actions.CREATE_POLICY_ERROR
  }),
  deletePolicies: (params, callback) => ({
    type: actions.DELETE_POLICIES,
    params,
    callback
  }),
  deletePoliciesSuccess: () => ({
    type: actions.DELETE_POLICIES_SUCCESS
  }),
  deletePoliciesError: () => ({
    type: actions.DELETE_POLICIES_ERROR
  }),
  getAllPoliciesWithoutPagination: (params) => ({
    type: actions.LOAD_POLICIES_WITHOUT_PAGINATION,
    params
  }),
  getAllPoliciesWithoutPaginationSuccess: (allPolicies) => ({
    type: actions.LOAD_POLICIES_WITHOUT_PAGINATION_SUCCESS,
    allPolicies
  }),
  getAllPoliciesWithoutPaginationError: () => ({
    type: actions.LOAD_POLICIES_WITHOUT_PAGINATION_ERROR
  }),
  resetAllPolicies: () => ({
    type: actions.RESET_ALL_POLICIES
  }),
  loadPolicies: (params) => ({
    type: actions.LOAD_POLICIES,
    params
  }),
  loadPoliciesSuccess: (policies, meta) => ({
    type: actions.LOAD_POLICIES_SUCCESS,
    policies,
    meta
  }),
  loadPoliciesError: () => ({
    type: actions.LOAD_POLICIES_ERROR
  }),
  getPolicy: (policyId) => ({
    type: actions.LOAD_POLICY,
    payload: {
      policyId
    }
  }),
  getPolicySuccess: (policy) => ({
    type: actions.LOAD_POLICY_SUCCESS,
    policy
  }),
  getPolicyError: () => ({
    type: actions.LOAD_POLICY_ERROR
  }),
  // This action is used for getting a list of organizations that are authorized for the current policy (! not policy authorizations)
  // These organizations are used in the Policy Details for the display of the organizations list
  getPolicyOrganizationsWithAccess: (policyId) => ({
    type: actions.GET_POLICY_ORGANIZATIONS_WITH_ACCESS,
    policyId
  }),
  getPolicyOrganizationsWithAccessSuccess: (organizations) => ({
    type: actions.GET_POLICY_ORGANIZATIONS_WITH_ACCESS_SUCCESS,
    organizations
  }),
  getPolicyOrganizationsWithAccessError: () => ({
    type: actions.GET_POLICY_ORGANIZATIONS_WITH_ACCESS_ERROR
  }),
  getPolicyAuth: (policyId) => ({
    type: actions.LOAD_POLICY_AUTH,
    policyId
  }),
  getPolicyAuthSuccess: (auth) => ({
    type: actions.LOAD_POLICY_AUTH_SUCCESS,
    auth
  }),
  getPolicyAuthError: (error) => ({
    type: actions.LOAD_POLICY_AUTH_ERROR,
    error
  }),
  updatePolicy: (policy, callback) => ({
    type: actions.UPDATE_POLICY,
    policy,
    callback
  }),
  updatePolicySuccess: () => ({
    type: actions.UPDATE_POLICY_SUCCESS
  }),
  updatePolicyError: () => ({
    type: actions.UPDATE_POLICY_ERROR
  }),
  getCustomValues: (insured_risk_type) => ({
    type: actions.GET_CUSTOM_VALUES,
    insured_risk_type
  }),
  getCustomValuesSuccess: (customValues) => ({
    type: actions.GET_CUSTOM_VALUES_SUCCESS,
    customValues
  }),
  getCustomValuesError: () => ({
    type: actions.GET_CUSTOM_VALUES_ERROR
  }),
  clearPolicyErrors: () => ({
    type: actions.CLEAR_POLICY_ERRORS
  }),
  downloadImportTemplate: () => ({
    type: actions.DOWNLOAD_IMPORT_TEMPLATE
  }),
  downloadImportTemplateSuccess: () => ({
    type: actions.DOWNLOAD_IMPORT_TEMPLATE_SUCCESS
  }),
  downloadImportTemplateError: () => ({
    type: actions.DOWNLOAD_IMPORT_TEMPLATE_ERROR
  }),
  importPoliciesFile: (file, housingStock) => ({
    type: actions.IMPORT_POLICIES_FILE,
    file,
    housingStock
  }),
  importPoliciesFileSuccess: (data) => ({
    type: actions.IMPORT_POLICIES_FILE_SUCCESS,
    data
  }),
  importPoliciesFileError: () => ({
    type: actions.IMPORT_POLICIES_FILE_ERROR
  }),
  clearImportPoliciesFileErrors: () => ({
    type: actions.CLEAR_IMPORT_POLICIES_FILE_ERRORS
  }),
  importPolicies: (policiesToCreate, policiesToUpdate, queryParams) => ({
    type: actions.IMPORT_POLICIES,
    policiesToCreate,
    policiesToUpdate,
    queryParams
  }),
  importCreatePolicies: () => ({
    type: actions.IMPORT_CREATE_POLICIES
  }),
  importCreatePoliciesSuccess: () => ({
    type: actions.IMPORT_CREATE_POLICIES_SUCCESS
  }),
  importCreatePoliciesError: () => ({
    type: actions.IMPORT_CREATE_POLICIES_ERROR
  }),
  importUpdatePolicies: () => ({
    type: actions.IMPORT_UPDATE_POLICIES
  }),
  importUpdatePoliciesSuccess: () => ({
    type: actions.IMPORT_UPDATE_POLICIES_SUCCESS
  }),
  importUpdatePoliciesError: () => ({
    type: actions.IMPORT_UPDATE_POLICIES_ERROR
  }),
  importPoliciesError: () => ({
    type: actions.IMPORT_POLICIES_ERROR
  }),
  clearImportPoliciesErrors: () => ({
    type: actions.CLEAR_IMPORT_POLICIES_ERRORS
  }),
  exportPolicies: (params) => ({
    type: actions.EXPORT_POLICIES,
    params
  }),
  exportPoliciesSuccess: () => ({
    type: actions.EXPORT_POLICIES_SUCCESS
  }),
  exportPoliciesError: () => ({
    type: actions.EXPORT_POLICIES_ERROR
  }),
  resetPolicies: () => ({
    type: actions.RESET_POLICIES
  }),
  bulkAssignInsuredRisks: (selectedInsuredRisks, policies, insuredRisksToPolicies) => ({
    type: actions.BULK_ASSIGN_INSURED_RISKS,
    selectedInsuredRisks,
    policies,
    insuredRisksToPolicies
  }),
  bulkAssignInsuredRisksSuccess: () => ({
    type: actions.BULK_ASSIGN_INSURED_RISKS_SUCCESS
  }),
  bulkAssignInsuredRisksError: () => ({
    type: actions.BULK_ASSIGN_INSURED_RISKS_ERROR
  }),
  bulkUnassignInsuredRisks: (insuredRisks, policies) => ({
    type: actions.BULK_UNASSIGN_INSURED_RISKS,
    insuredRisks,
    policies
  }),
  bulkUnassignInsuredRisksSuccess: () => ({
    type: actions.BULK_UNASSIGN_INSURED_RISKS_SUCCESS
  }),
  bulkUnassignInsuredRisksError: () => ({
    type: actions.BULK_UNASSIGN_INSURED_RISKS_ERROR
  }),
  clearSinglePolicy: () => ({
    type: actions.CLEAR_SINGLE_POLICY
  }),
  getConnectedBuildings: (params) => ({
    type: actions.GET_CONNECTED_BUILDINGS,
    params
  }),
  getConnectedBuildingsSuccess: (payload) => ({
    type: actions.GET_CONNECTED_BUILLDINGS_SUCCESS,
    payload
  }),
  getConnectedBuildingsError: () => ({
    type: actions.GET_CONNECTED_BUILLDINGS_ERROR
  }),
  getPoliciesInsuredRiskTypes: () => ({
    type: actions.GET_POLICIES_INSURED_RISK_TYPES
  }),
  getPoliciesInsuredRiskTypesSuccess: (payload) => ({
    type: actions.GET_POLICIES_INSURED_RISK_TYPES_SUCCESS,
    payload
  }),
  getPoliciesInsuredRiskTypesError: () => ({
    type: actions.GET_POLICIES_INSURED_RISK_TYPES_ERROR
  }),
  getPoliciesInsuredRisks: () => ({
    type: actions.GET_POLICIES_INSURED_RISKS
  }),
  getPoliciesInsuredRisksSuccess: (payload) => ({
    type: actions.GET_POLICIES_INSURED_RISKS_SUCCESS,
    payload
  }),
  getPoliciesInsuredRisksError: () => ({
    type: actions.GET_POLICIES_INSURED_RISKS_ERROR
  }),
  getPoliciesFiltersOrganizationsWithAccess: () => ({
    type: actions.GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS
  }),
  getPoliciesFiltersOrganizationsWithAccessSuccess: (payload) => ({
    type: actions.GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_SUCCESS,
    payload
  }),
  getPoliciesFiltersOrganizationsWithAccessError: () => ({
    type: actions.GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS_ERROR
  }),
  spreadSinglePolicyIntoPolicies: (policy) => ({
    type: actions.SPREAD_SINGLE_POLICY_INTO_POLICIES,
    policy
  }),

  setLastVisitedPolicyId: (policyId) => ({
    type: actions.SET_LAST_VISITED_POLICY_ID,
    policyId
  }),

  resetLastVisitedPolicyId: () => ({
    type: actions.RESET_LAST_VISITED_POLICY_ID
  }),

  setInsuranceOrganizationLogos: (organizationLogos) => ({
    type: actions.SET_INSURANCE_ORGANIZATION_LOGOS,
    organizationLogos
  })
};
export default actions;
