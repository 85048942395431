import axios from '../../config/axios';
import apiUrls from '../apiUrls';

/**
 * Creates new buildings.
 * @param {Array} buildings - Array of buildings
 * @returns {Array} Array of the buildings created
 */
const onCreateBuildings = async (buildings) =>
  axios
    .post(apiUrls.buildingsBaseUrl, buildings)
    .then((res) => res)
    .catch((error) => error);

/**
 * Updates existing buildings.
 * @param {Array} buildings - Array of buildings
 * @returns {Array} updated buildings
 */
const onUpdateBuildings = async (buildings) =>
  axios
    .put(apiUrls.buildingsBaseUrl, buildings)
    .then((res) => res)
    .catch((error) => error);

/**
 * Returns an array of buildings that match the filter parameters.
 * @param {Object} params - filter parameters to be used to filter the list of buildings
 * @returns {Array} Array of buildings
 */
const onGetBuildings = async (params) =>
  axios
    .get(apiUrls.buildingsBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onExportBuildings = async (buildingsIds) =>
  axios
    .post(`${apiUrls.buildingsBaseUrl}/create-export-file`, buildingsIds, { responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((error) => error);

/**
 * Expects a building id and returns a building object.
 * @param {string} buildingId - Building id
 * @returns {object} Building object
 */
const onGetBuilding = async (buildingId) =>
  axios
    .get(`${apiUrls.buildingsBaseUrl}/${buildingId}`)
    .then((res) => res)
    .catch((error) => error);

const onDeleteBuildings = async (params) =>
  axios
    .post(`${apiUrls.buildingsBaseUrl}/delete-many`, params)
    .then((res) => res)
    .catch((error) => error);

const onGetBuildingsCompanyCodes = async () =>
  axios
    .get(`${apiUrls.buildingsBaseUrl}/company-codes`)
    .then((res) => res)
    .catch((error) => error);

const onGetBuildingsEconomicUnits = async () =>
  axios
    .get(`${apiUrls.buildingsBaseUrl}/economic-units`)
    .then((res) => res)
    .catch((error) => error);

const onGetBuildingsHousingStocks = async () =>
  axios
    .get(`${apiUrls.buildingsBaseUrl}/housing-stocks`)
    .then((res) => res)
    .catch((error) => error);

export {
  onCreateBuildings,
  onUpdateBuildings,
  onGetBuildings,
  onGetBuilding,
  onExportBuildings,
  onGetBuildingsHousingStocks,
  onGetBuildingsCompanyCodes,
  onGetBuildingsEconomicUnits,
  onDeleteBuildings
};
