const actions = {
  CREATE_BUILDING_TO_POLICIES: 'CREATE_BUILDING_TO_POLICIES',
  CREATE_BUILDING_TO_POLICIES_ERROR: 'CREATE_BUILDING_TO_POLICIES_ERROR',
  CREATE_BUILDING_TO_POLICIES_SUCCESS: 'CREATE_BUILDING_TO_POLICIES_SUCCESS',
  GET_BUILDING_TO_POLICIES: 'GET_BUILDING_TO_POLICIES',
  GET_BUILDING_TO_POLICIES_ERROR: 'GET_BUILDING_TO_POLICIES_ERROR',
  GET_BUILDING_TO_POLICIES_SUCCESS: 'GET_BUILDING_TO_POLICIES_SUCCESS',
  DELETE_MANY_BUILDING_TO_POLICIES: 'DELETE_MANY_BUILDING_TO_POLICIES',
  DELETE_MANY_BUILDING_TO_POLICIES_ERROR: 'DELETE_MANY_BUILDING_TO_POLICIES_ERROR',
  DELETE_MANY_BUILDING_TO_POLICIES_SUCCESS: 'DELETE_MANY_BUILDING_TO_POLICIES_SUCCESS',
  CLEAR_BUILDING_TO_POLICIES: 'CLEAR_BUILDING_TO_POLICIES',

  /**
   * Action to create a building to policies relationship
   * @param {Array} params - An array of objects that each contain a building and a policies id.
   * @param {boolean} showNotification - Whether or not to show a notification when a buildingToPolicies is created.
   * @property {string} params.building_id
   * @property {string} params.policy_id
   */
  createBuildingToPolicies: (params, showNotification) => ({
    type: actions.CREATE_BUILDING_TO_POLICIES,
    params,
    showNotification
  }),
  createBuildingToPoliciesError: () => ({
    type: actions.CREATE_BUILDING_TO_POLICIES_ERROR
  }),
  createBuildingToPoliciesSuccess: (payload) => ({
    type: actions.CREATE_BUILDING_TO_POLICIES_SUCCESS,
    payload
  }),

  /**
   * @param {Object} params
   * @property {Array} params.buildingId - array of building ids
   * @property {Array} params.policiesId - array of policies ids
   * @property {Boolean} params.paging
   * @property {Number} params.page
   * @property {Number} params.page_size
   */
  getBuildingToPolicies: (params) => ({
    type: actions.GET_BUILDING_TO_POLICIES,
    params
  }),
  getBuildingToPoliciesError: () => ({
    type: actions.GET_BUILDING_TO_POLICIES_ERROR
  }),
  getBuildingToPoliciesSuccess: (payload) => ({
    type: actions.GET_BUILDING_TO_POLICIES_SUCCESS,
    payload
  }),
  /**
   * @param {Array} params - Array of BuildingToPolicies ids to be deleted
   */
  deleteManyBuildingToPolicies: (params) => ({
    type: actions.DELETE_MANY_BUILDING_TO_POLICIES,
    params
  }),
  deleteManyBuildingToPoliciesError: () => ({
    type: actions.DELETE_MANY_BUILDING_TO_POLICIES_ERROR
  }),
  deleteManyBuildingToPoliciesSuccess: () => ({
    type: actions.DELETE_MANY_BUILDING_TO_POLICIES_SUCCESS
  }),
  clearBuildingToPolicies: () => ({
    type: actions.CLEAR_BUILDING_TO_POLICIES
  })
};

export default actions;
