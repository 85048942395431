import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  kpis: {},
  kpisLoading: false,
  kpisError: false,
  profitabilityKpis: {},
  profitabilityKpisLoading: false,
  profitabilityKpisError: false,
  setPremiumSettingsLoading: false,
  setPremiumSettingsError: false,
  totalSettlements: 0
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.LOAD_KPIS:
      return state.set('kpisLoading', true);
    case actions.LOAD_KPIS_SUCCESS:
      return state
        .set('kpis', action.kpis)
        .set('totalSettlements', parseInt(action.kpis.settlements_total, 10))
        .set('kpisLoading', false);
    case actions.LOAD_KPIS_ERROR:
      return state.set('kpisError', true).set('kpisLoading', false);
    case actions.LOAD_PROFITABILITY_KPIS:
      return state.set('profitabilityKpisLoading', true);
    case actions.LOAD_PROFITABILITY_KPIS_SUCCESS:
      return state.set('profitabilityKpis', action.kpis).set('profitabilityKpisLoading', false);
    case actions.LOAD_PROFITABILITY_KPIS_ERROR:
      return state.set('profitabilityKpisError', true).set('profitabilityKpisLoading', false);
    case actions.SET_PREMIUM_KPIS:
      return state.set('setPremiumSettingsLoading', true);
    case actions.SET_PREMIUM_KPIS_SUCCESS:
      return state.set('setPremiumSettingsLoading', false);
    case actions.SET_PREMIUM_KPIS_ERROR:
      return state.set('setPremiumSettingsError', true).set('setPremiumSettingsLoading', false);
    case actions.CLEAR_SET_PREMIUM_KPIS_ERROR:
      return state.set('setPremiumSettingsError', false);
    case actions.LOAD_PREMIUM_SETTINGS:
      return state.set('getPremiumSettingsLoading', true);
    case actions.LOAD_PREMIUM_SETTINGS_SUCCESS:
      return state.set('getPremiumSettingsLoading', false).set('premiumSettings', action.premiumSettings);
    case actions.LOAD_PREMIUM_SETTINGS_ERROR:
      return state.set('getPremiumSettingsError', true).set('getPremiumSettingsLoading', false);
    default:
      return state;
  }
};

export default reducer;
