import { Map } from 'immutable';
import actions from './actions';

const initialState = new Map({
  buildingToPolicies: [],
  getBuildingToPoliciesLoading: false,
  getBuildingToPoliciesError: false,
  createBuildingToPoliciesLoading: false,
  createBuildingToPoliciesError: false,
  deleteBuildingToPoliciesLoading: false,
  deleteBuildingToPoliciesError: false
});

const buildingToPoliciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_BUILDING_TO_POLICIES:
      return state.set('createBuildingToPoliciesLoading', true);
    case actions.CREATE_BUILDING_TO_POLICIES_SUCCESS:
      return state.set('createBuildingToPoliciesLoading', false);
    case actions.CREATE_BUILDING_TO_POLICIES_ERROR:
      return state.set('createBuildingToPoliciesLoading', false).set('createBuildingToPoliciesError', true);
    case actions.GET_BUILDING_TO_POLICIES:
      return state.set('getBuildingToPoliciesLoading', true);
    case actions.GET_BUILDING_TO_POLICIES_SUCCESS:
      return state.set('buildingToPolicies', action.payload).set('getBuildingToPoliciesLoading', false);
    case actions.GET_BUILDING_TO_POLICIES_ERROR:
      return state.set('getBuildingToPoliciesLoading', false).set('getBuildingToPoliciesError', true);
    case actions.DELETE_MANY_BUILDING_TO_POLICIES:
      return state.set('deleteBuildingToPoliciesLoading', true);
    case actions.DELETE_MANY_BUILDING_TO_POLICIES_SUCCESS:
      return state.set('deleteBuildingToPoliciesLoading', false);
    case actions.DELETE_MANY_BUILDING_TO_POLICIES_ERROR:
      return state.set('deleteBuildingToPoliciesLoading', false).set('deleteBuildingToPoliciesError', true);
    case actions.CLEAR_BUILDING_TO_POLICIES:
      return state.set('buildingToPolicies', []);
    default:
      return state;
  }
};

export default buildingToPoliciesReducer;
