const actions = {
  UPLOAD_IMPORT_FILE: 'UPLOAD_IMPORT_FILE',
  CLEAR_IMPORT_FILE: 'REMOVE_IMPORT_FILE',
  GET_IMPORT_TEMPLATE: 'GET_IMPORT_TEMPLATE',
  GET_IMPORT_TEMPLATE_SUCCESS: 'GET_IMPORT_TEMPLATE_SUCCESS',
  GET_IMPORT_TEMPLATE_ERROR: 'GET_IMPORT_TEMPLATE_ERROR',
  CHECK_IMPORT_FILE: 'CHECK_IMPORT_FILE',
  CHECK_IMPORT_FILE_SUCCESS: 'CHECK_IMPORT_FILE_SUCCESS',
  CHECK_IMPORT_FILE_ERROR: 'CHECK_IMPORT_FILE_ERROR',
  EXECUTE_IMPORT: 'EXECUTE_IMPORT',
  EXECUTE_IMPORT_SUCCESS: 'EXECUTE_IMPORT_SUCCESS',
  EXECUTE_IMPORT_ERROR: 'EXECUTE_IMPORT_ERROR',

  uploadImportFile: (file) => ({
    type: actions.UPLOAD_IMPORT_FILE,
    payload: file
  }),

  clearImportFile: () => ({
    type: actions.CLEAR_IMPORT_FILE
  }),

  getImportTemplate: () => ({
    type: actions.GET_IMPORT_TEMPLATE
  }),

  getImportTemplateSuccess: () => ({
    type: actions.GET_IMPORT_TEMPLATE_SUCCESS
  }),

  getImportTemplateError: () => ({
    type: actions.GET_IMPORT_TEMPLATE_ERROR
  }),

  checkImportFile: (file) => ({
    type: actions.CHECK_IMPORT_FILE,
    file
  }),

  checkImportFileSuccess: (importData) => ({
    type: actions.CHECK_IMPORT_FILE_SUCCESS,
    payload: importData
  }),

  checkImportFileError: () => ({
    type: actions.CHECK_IMPORT_FILE_ERROR
  }),

  executeImport: (file) => ({
    type: actions.EXECUTE_IMPORT,
    file
  }),

  executeImportSuccess: () => ({
    type: actions.EXECUTE_IMPORT_SUCCESS
  }),

  executeImportError: () => ({
    type: actions.EXECUTE_IMPORT_ERROR
  })
};

export default actions;
