import { all, takeEvery, put, call } from 'redux-saga/effects';
import { onLoadKpis, onLoadProfitabilityKpis, onSetPremiumKpis, onLoadPremiumSettings } from './apiCalls';
import actions from './actions';

export function* loadKpis({ params }) {
  try {
    const response = yield call(onLoadKpis, params);
    if (response.data) {
      yield put(actions.getKpisSuccess(response.data));
    } else {
      yield put(actions.getKpisError());
    }
  } catch (error) {
    yield put(actions.getKpisError());
  }
}

export function* loadProfitabilityKpis({ params }) {
  try {
    const response = yield call(onLoadProfitabilityKpis, params);
    if (response.data) {
      yield put(actions.getProfitabilityKpisSuccess(response.data));
    } else {
      yield put(actions.getProfitabilityKpisError());
    }
  } catch (error) {
    yield put(actions.getProfitabilityKpisError());
  }
}

export function* loadPremiumSettings() {
  try {
    const response = yield call(onLoadPremiumSettings);
    if (response.data) {
      yield put(actions.getPremiumSettingsSuccess(response.data));
    } else {
      yield put(actions.getPremiumSettingsError());
    }
  } catch (error) {
    yield put(actions.getPremiumSettingsError());
  }
}

export function* setPremiumKpis({ params, queryParams }) {
  try {
    const response = yield call(onSetPremiumKpis, params);
    if (response.status === 200) {
      const { year_from, year_to, housing_stock_id } = queryParams;
      yield put(actions.setPremiumKpisSuccess());
      yield put(actions.getPremiumSettings());
      yield put(actions.getProfitabilityKpis({ year_from, year_to, housing_stock_id }));
    } else {
      yield put(actions.setPremiumKpisError());
    }
  } catch (error) {
    yield put(actions.setPremiumKpisError());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_KPIS, loadKpis),
    takeEvery(actions.LOAD_PROFITABILITY_KPIS, loadProfitabilityKpis),
    takeEvery(actions.SET_PREMIUM_KPIS, setPremiumKpis),
    takeEvery(actions.LOAD_PREMIUM_SETTINGS, loadPremiumSettings)
  ]);
}
