import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SearchSelectModalLayout from '../Layout/Modals/SearchSelectModalLayout';
import { Button, Select, Spinner, Tooltip } from '../index';
import { IconCircledPlus, IconSearch, IconCircledCheck } from '../Icons';
import { getTitleFromObject, highlightMatches, isMobile } from '../../../helpers/utility';
import { CLAIM_VALIDATION_TOOLTIP } from '../../../consts/dictionaries';

const { Option } = Select;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  border-bottom: 1px solid var(--greyscale-dark-white);
  display: flex;

  .ant-btn {
    justify-content: flex-start;
    width: 100%;
  }
`;

const ValueWrapper = styled.span`
  display: block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
`;

const { SearchIconWrapper } = SearchSelectModalLayout;

const searchIcon = (
  <SearchIconWrapper>
    <IconSearch />
  </SearchIconWrapper>
);

/**
 * SearchSelectInput is a React component that renders a searchable select input.
 * The options for the select input are passed in the `options` prop.
 * The `onSearch` and `onChange` callbacks are triggered when the user searches or changes the selection.
 * The `notFoundContent` prop allows for customizing the message displayed when no options are found.
 * The `searchValue` prop is used to control the search input value.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Object} props.config
 * @param {string} props.optionsObjectTypes - The type of the options objects.
 * @param {boolean} props.disabled - Determines if the select input is disabled.
 * @param {Array} props.options - The options for the select input.
 * @param {Function} props.onSearch - The callback function when the user searches.
 * @param {Function} props.onChange - The callback function when the selection changes.
 * @param {string} props.notFoundContent - The message displayed when no options are found.
 * @param {boolean} props.isSearchLoading - Determines if the API call is loading.
 * @param {string} props.searchValue - The value of the search input.
 * @returns {React.Element} The rendered SearchSelectInput component.
 */
const SearchSelectInputComponent = ({
  config,
  isSearchLoading,
  value,
  optionsObjectTypes = '',
  disabled = false,
  options = [],
  onSearch = () => {},
  onChange = () => {},
  notFoundContent = 'Keine Ergebnisse gefunden',
  searchValue = ''
}) => {
  const [isCustomOptionSelected, setIsCustomOptionSelected] = useState(false);

  const isCustomInputSelectable = config?.simple_claim_ui_enabled;

  useEffect(() => {
    const isUUID4 = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    if (searchValue?.length > 0) {
      setIsCustomOptionSelected(false);
    } else if (searchValue?.length === 0 && value) {
      if (isUUID4.test(value)) {
        setIsCustomOptionSelected(false);
      } else {
        setIsCustomOptionSelected(true);
      }
    }
  }, [searchValue]);

  useEffect(() => {
    // The value property of the component usually comes from the Form.Item.
    // The value property can have a uuid4 value or a string value. If it's a string then the user has selected the custom option.
    const isUUID4 = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    if (value && !isUUID4.test(value)) {
      setIsCustomOptionSelected(true);
    } else {
      setIsCustomOptionSelected(false);
    }
  }, [value]);

  const getOptionsContent = () => {
    if (isSearchLoading) {
      const loadingStateOption = (
        <Option key="loading" disabled>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        </Option>
      );
      return [loadingStateOption];
    }

    return options.map((option) => {
      // ! Please keep into mind that `getTitleFromObject` it doesn't work for all cases. Specifically for the `insured-risk-to-policy` object type.
      const title = getTitleFromObject(option, optionsObjectTypes);
      const classNames = 'result';

      const returnCheckIcon = () => {
        if (isMobile) {
          return (
            <span>
              <IconCircledCheck />
            </span>
          );
        }

        return (
          <Tooltip mouseEnterDelay={0.1} placement="bottom" title={CLAIM_VALIDATION_TOOLTIP.building}>
            <IconCircledCheck />
          </Tooltip>
        );
      };

      const highlightedTitle = highlightMatches(title, searchValue, classNames, returnCheckIcon());

      return (
        <Option key={option.id} id={option.id} className="no-checkmark">
          {highlightedTitle}
        </Option>
      );
    });
  };

  const _options = getOptionsContent();

  // render dropdown with action button and label if user input is selectable
  const renderDropdownRender = () => {
    // If the user has not typed anything in the search field and if the custom option is not selected, then we don't render the custom option.
    if (searchValue?.length === 0 && !isCustomOptionSelected) {
      return null;
    }

    let customOption = React.Fragment;
    if (isCustomInputSelectable || isCustomOptionSelected) {
      const showButton = searchValue?.length > 0;
      const showLabel = options?.length > 0;

      // If a custom option is selected, then the value is used in order to show the custom option as a selected entry in the dropdown
      // Otherwise a button containing the search value is shown giving the user the option to select the custom option.
      const valueToUse = isCustomOptionSelected ? value : searchValue;
      const className = !isCustomOptionSelected ? 'custom-option no-checkmark' : 'no-checkmark';

      const separatorOption = (
        <Option
          className="custom-option-label no-checkmark"
          key="custom-option-label"
          disabled
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <>Bekannte Objekte</>
        </Option>
      );

      const getOptionContent = () => {
        // Even if the user can not select a new custom option, the previously selected one should be displayed in the dropdown.
        // Therefore the selected custom option is shown even when isCustimInputSelectable is false.
        if (isCustomOptionSelected) {
          return valueToUse;
        }

        // The button for selecting a custom option is only shown if the setting for creating simple claims is enabled.
        if (isCustomInputSelectable) {
          return (
            <DropdownWrapper>
              {showButton && (
                <ButtonWrapper>
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    type="text"
                    icon={<IconCircledPlus />}
                  >
                    <ValueWrapper>„{valueToUse}“</ValueWrapper>
                    &nbsp;
                    {isMobile ? 'verwenden' : 'als Schadenort verwenden'}
                  </Button>
                </ButtonWrapper>
              )}
            </DropdownWrapper>
          );
        }

        return null;
      };

      customOption = (
        <React.Fragment key="custom-option-wrapper">
          <Option className={className} key="custom-option" title={`${valueToUse}`}>
            {getOptionContent()}
          </Option>
          {showLabel && separatorOption}
        </React.Fragment>
      );
    }

    return customOption;
  };

  if (isCustomInputSelectable || isCustomOptionSelected) {
    const customOption = renderDropdownRender();
    _options.unshift(customOption);
  }

  const getValueForSelect = () => {
    // When the user selects a custom string, the value property of the SearchSelectInput component will be set to that string.
    // However the value property of the Select Elemant has to corespond to a key in the options array.
    // Therefore, we have to provide the "custom-option" string to the value property. This coresponds to the key of the custom option in the options array.
    if (isCustomOptionSelected) {
      return 'custom-option';
    }
    return value;
  };

  return (
    <Select
      disabled={disabled}
      showSearch
      placeholder="Suchen"
      className="search-select-input"
      onSearch={onSearch}
      onChange={onChange}
      filterOption={false}
      notFoundContent={notFoundContent}
      searchValue={searchValue}
      suffixIcon={searchIcon}
      value={getValueForSelect()}
    >
      {_options}
    </Select>
  );
};

const mapStateToProps = (state) => ({
  config: state.Config.get('config')
});

SearchSelectInputComponent.displayName = 'SearchSelectInput';

const SearchSelectInput = connect(mapStateToProps)(SearchSelectInputComponent);

export { SearchSelectInput };
