import axios from '../../config/axios';
import apiUrls from '../apiUrls';

// --------------------------- API CALLS FOR CLAIMS LIST--------------------------- //
const onLoadAll = async (params) =>
  axios
    .get(apiUrls.claimBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadClaimsInsuredRiskTypes = async () =>
  axios
    .get(`${apiUrls.claimBaseUrl}/insured-risk-types`)
    .then((res) => res)
    .catch((error) => error);

const onLoadCreatedByOrganizations = async () =>
  axios
    .get(`${apiUrls.claimBaseUrl}/created-by-organizations`)
    .then((res) => res)
    .catch((error) => error);

const onGetClaimsFiltersOrganizationsWithAccess = async () =>
  axios
    .get(`${apiUrls.claimBaseUrl}/organizations-with-access`)
    .then((res) => res)
    .catch((error) => error);

const onLoadAllWorklistClaims = async (params) =>
  axios
    .get(`${apiUrls.claimBaseUrl}?active_messages=true&sort=-updated_at`, { params })
    .then((res) => res)
    .catch((error) => error);

const onLoadMapBuildings = async (params) =>
  axios
    .get(`${apiUrls.claimBaseUrl}/building-map-points`, { params })
    .then((res) => res)
    .catch((error) => error);

const onExportClaims = async (claimsIds) =>
  axios
    .post(`${apiUrls.claimBaseUrl}/create-export-file`, claimsIds, { responseType: 'arraybuffer' })
    .then((res) => res)
    .catch((error) => error);

// --------------------------- API CALLS FOR SINGLE CLAIM --------------------------- //
const onGetClaim = async (claimId) =>
  axios
    .get(`${apiUrls.claimBaseUrl}/${claimId}`)
    .then((response) => response)
    .catch((error) => error);

const onGetClaimHistory = async (claimId) =>
  axios
    .get(`${apiUrls.claimBaseUrl}/${claimId}/history`)
    .then((response) => response)
    .catch((error) => error);

const onUpdateClaim = async (claim) =>
  axios
    .put(`${apiUrls.claimBaseUrl}/${claim.id}`, claim)
    .then((res) => res)
    .catch((error) => error);

const onCreateClaim = async (claimData) =>
  axios
    .post(apiUrls.claimBaseUrl, claimData)
    .then((res) => res)
    .catch((error) => error);

const onUpdateClaimStatus = async (claimId, status) =>
  axios
    .patch(`${apiUrls.claimBaseUrl}/${claimId}/status=${status}`)
    .then((res) => res)
    .catch((error) => error);

const onGetClaimOrganizationsWithAccess = async (claimId) =>
  axios
    .get(`${apiUrls.claimBaseUrl}/${claimId}/organizations-with-access`)
    .then((res) => res)
    .catch((error) => error);

const onDownloadReport = async (claimId) =>
  axios
    .get(`${apiUrls.claimBaseUrl}/${claimId}/pdf-summary`, {
      responseType: 'blob',
      headers: { Accept: 'application/pdf' }
    })
    .then((res) => res)
    .catch((error) => error);

export {
  onLoadAll,
  onLoadAllWorklistClaims,
  onGetClaimHistory,
  onLoadMapBuildings,
  onGetClaim,
  onUpdateClaim,
  onCreateClaim,
  onUpdateClaimStatus,
  onLoadCreatedByOrganizations,
  onGetClaimsFiltersOrganizationsWithAccess,
  onExportClaims,
  onDownloadReport,
  onGetClaimOrganizationsWithAccess,
  onLoadClaimsInsuredRiskTypes
};
