const actions = {
  CREATE_BUILDINGS: 'CREATE_BUILDINGS',
  CREATE_BUILDINGS_ERROR: 'CREATE_BUILDINGS_ERROR',
  CREATE_BUILDINGS_SUCCESS: 'CREATE_BUILDINGS_SUCCESS',
  GET_BUILDING: 'GET_BUILDING',
  GET_BUILDING_ERROR: 'GET_BUILDING_ERROR',
  GET_BUILDING_SUCCESS: 'GET_BUILDING_SUCCESS',
  GET_BUILDINGS: 'GET_BUILDINGS',
  GET_BUILDINGS_ERROR: 'GET_BUILDINGS_ERROR',
  GET_BUILDINGS_SUCCESS: 'GET_BUILDINGS_SUCCESS',
  GET_ALL_BUILDINGS: 'GET_ALL_BUILDINGS',
  GET_ALL_BUILDINGS_ERROR: 'GET_ALL_BUILDINGS_ERROR',
  GET_ALL_BUILDINGS_SUCCESS: 'GET_ALL_BUILDINGS_SUCCESS',
  UPDATE_BUILDINGS: 'UPDATE_BUILDINGS',
  UPDATE_BUILDINGS_ERROR: 'UPDATE_BUILDINGS_ERROR',
  UPDATE_BUILDINGS_SUCCESS: 'UPDATE_BUILDINGS_SUCCESS',
  EXPORT_BUILDINGS: 'EXPORT_BUILDINGS',
  EXPORT_BUILDINGS_SUCCESS: 'EXPORT_BUILDINGS_SUCCESS',
  EXPORT_BUILDINGS_ERROR: 'EXPORT_BUILDINGS_ERROR',
  DELETE_BUILDINGS: 'DELETE_BUILDINGS',
  DELETE_BUILDINGS_ERROR: 'DELETE_BUILDINGS_ERROR',
  DELETE_BUILDINGS_SUCCESS: 'DELETE_BUILDINGS_SUCCESS',
  RESET_BUILDINGS: 'RESET_BUILDINGS',
  RESET_ALL_BUILDINGS: 'RESET_ALL_BUILDINGS',
  GET_BUILDINGS_HOUSING_STOCKS: 'GET_BUILDINGS_HOUSING_STOCKS',
  GET_BUILDINGS_HOUSING_STOCKS_ERROR: 'GET_BUILDINGS_HOUSING_STOCKS_ERROR',
  GET_BUILDINGS_HOUSING_STOCKS_SUCCESS: 'GET_BUILDINGS_HOUSING_STOCKS_SUCCESS',
  GET_BUILDINGS_COMPANY_CODES: 'GET_BUILDINGS_COMPANY_CODES',
  GET_BUILDINGS_COMPANY_CODES_ERROR: 'GET_BUILDINGS_COMPANY_CODES_ERROR',
  GET_BUILDINGS_COMPANY_CODES_SUCCESS: 'GET_BUILDINGS_COMPANY_CODES_SUCCESS',
  GET_BUILDINGS_ECONOMIC_UNITS: 'GET_BUILDINGS_ECONOMIC_UNITS',
  GET_BUILDINGS_ECONOMIC_UNITS_ERROR: 'GET_BUILDINGS_ECONOMIC_UNITS_ERROR',
  GET_BUILDINGS_ECONOMIC_UNITS_SUCCESS: 'GET_BUILDINGS_ECONOMIC_UNITS_SUCCESS',
  CLEAR_SINGLE_BUILDING: 'CLEAR_SINGLE_BUILDING',
  GET_CONNECTED_POLICIES: 'GET_CONNECTED_POLICIES',
  GET_CONNECTED_POLICIES_ERROR: 'GET_CONNECTED_POLICIES_ERROR',
  GET_CONNECTED_POLICIES_SUCCESS: 'GET_CONNECTED_POLICIES_SUCCESS',
  CLEAR_CONNECTED_POLICIES: 'CLEAR_CONNECTED_POLICIES',
  SET_LAST_VISITED_BUILDING_ID: 'SET_LAST_VISITED_BUILDING_ID',
  RESET_LAST_VISITED_BUILDING_ID: 'RESET_LAST_VISITED_BUILDING_ID',

  /**
   * Provide a list of buildings to the reducer
   * @param {Array} buildings - The list of buildings to be created
   * @param {Array} linkedPolicies - The policies linked to this buidings
   */
  createBuildings: (buildings, linkedPolicies, callback, navigate) => ({
    type: actions.CREATE_BUILDINGS,
    buildings,
    linkedPolicies,
    callback,
    navigate
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias createBuildingsError
   */
  createBuildingsError: () => ({
    type: actions.CREATE_BUILDINGS_ERROR
  }),

  /**
   * Returns an array that contains the newly created buildings
   * @param {Array} payload - Array of buildings that have been created
   * @memberof actions
   * @alias createBuildingsSuccess
   */
  createBuildingsSuccess: (payload) => ({
    type: actions.CREATE_BUILDINGS_SUCCESS,
    payload
  }),

  /**
   * Provide a set of filter parameters to the reducer.
   * Returns a list of buildings that match the filter parameters.
   * @param {Object} params - filter parameters to be used to filter the list of buildings
   * @memberof actions
   * @alias getBuilding
   */
  getBuildings: (params) => ({
    type: actions.GET_BUILDINGS,
    params
  }),

  /**
   * Provide an error to the reducer
   *  @memberof actions
   * @alias getBuildingsError
   */
  getBuildingsError: () => ({
    type: actions.GET_BUILDINGS_ERROR
  }),

  /**
   * Provide a success message to the reducer
   * @param {Array} payload - List of buildings
   * @memberof actions
   * @alias getBuildingsSuccess
   */
  getBuildingsSuccess: (payload, meta) => ({
    type: actions.GET_BUILDINGS_SUCCESS,
    payload,
    meta
  }),

  /**
   * Provide a building ID to the reducer
   * @param {string} buildingId
   * @memberof actions
   * @alias getBuilding
   */
  getBuilding: (buildingId) => ({
    type: actions.GET_BUILDING,
    buildingId
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias getBuildingError
   */
  getBuildingError: () => ({
    type: actions.GET_BUILDING_ERROR
  }),

  /**
   * Provide the building to the reducer
   * @param {Object} payload - The building
   * @memberof actions
   * @alias getBuildingSuccess
   */
  getBuildingSuccess: (payload) => ({
    type: actions.GET_BUILDING_SUCCESS,
    payload
  }),

  /**
   * Action to fetch all buildings that match the buildingsActiveFilters o the Filters reducer
   * Response is not paginated
   */
  getAllBuildings: () => ({
    type: actions.GET_ALL_BUILDINGS
  }),

  getAllBuildingsError: () => ({
    type: actions.GET_ALL_BUILDINGS_ERROR
  }),

  getAllBuildingsSuccess: (payload) => ({
    type: actions.GET_ALL_BUILDINGS_SUCCESS,
    payload
  }),

  /**
   * Clears the allBuildings state in the reducer
   */
  resetAllBuildings: () => ({
    type: actions.RESET_ALL_BUILDINGS
  }),

  /**
   * Provide to the reducer the list of buildings that we want to update
   * @param {Array} buildings - The list of buildings to be updated
   * @memberof actions
   * @alias updateBuildings
   */
  updateBuilding: (buildings, callback) => ({
    type: actions.UPDATE_BUILDINGS,
    buildings,
    callback
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias updateBuildingsError
   */
  updateBuildingsError: () => ({
    type: actions.UPDATE_BUILDINGS_ERROR
  }),

  /**
   * Provide to the reducer the list of buildings that have been updated
   * @param {Array} payload - The list of buildings that have been updated
   * @memberof actions
   * @alias updateBuildingsSuccess
   */
  updateBuildingsSuccess: (buildings) => ({
    type: actions.UPDATE_BUILDINGS_SUCCESS,
    buildings
  }),
  /**
   * @memberof actions
   * @alias exportBuildings
   */
  exportBuildings: (params) => ({
    type: actions.EXPORT_BUILDINGS,
    params
  }),
  /**
   * @memberof actions
   * @alias exportBuildingsSuccess
   */
  exportBuildingsSuccess: () => ({
    type: actions.EXPORT_BUILDINGS_SUCCESS
  }),
  /**
   * Provides an error to the reducer
   * @memberof actions
   * @alias exportBuildingsError
   */
  exportBuildingsError: () => ({
    type: actions.EXPORT_BUILDINGS_ERROR
  }),
  /**
   * Provide to the reducer the list of buildings that we want to delete
   * @param {Array} payload - The list of buildings ids to be deleted
   * @memberof actions
   * */
  deleteBuildings: (payload, callback) => ({
    type: actions.DELETE_BUILDINGS,
    payload,
    callback
  }),

  deleteBuildingsError: () => ({
    type: actions.DELETE_BUILDINGS_ERROR
  }),

  deleteBuildingsSuccess: () => ({
    type: actions.DELETE_BUILDINGS_SUCCESS
  }),

  /**
   * @memberof actions
   * @alias resetBuildings
   */
  resetBuildings: () => ({
    type: actions.RESET_BUILDINGS
  }),

  /**
   * @memberof actions
   * @alias getBuildingsCompanyCodes
   */
  getBuildingsCompanyCodes: () => ({
    type: actions.GET_BUILDINGS_COMPANY_CODES
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias getBuildingsCompanyCodesError
   */
  getBuildingsCompanyCodesError: () => ({
    type: actions.GET_BUILDINGS_COMPANY_CODES_ERROR
  }),

  /**
   * Provide the list of company codes to the reducer
   * @param {Array} companyCodes - The list of company codes
   * @memberof actions
   * @alias getBuildingsCompanyCodesSuccess
   */
  getBuildingsCompanyCodesSuccess: (payload) => ({
    type: actions.GET_BUILDINGS_COMPANY_CODES_SUCCESS,
    payload
  }),

  /**
   * @memberof actions
   * @alias getBuildingsEconomicUnits
   */
  getBuildingsEconomicUnits: () => ({
    type: actions.GET_BUILDINGS_ECONOMIC_UNITS
  }),

  /**
   * Provide an error to the reducer
   * @memberof actions
   * @alias getBuildingsEconomicUnitsError
   */
  getBuildingsEconomicUnitsError: () => ({
    type: actions.GET_BUILDINGS_ECONOMIC_UNITS_ERROR
  }),

  /**
   * Provide the list of economic units to the reducer
   * @param {Array} companyCodes - The list of economic units
   * @memberof actions
   * @alias getBuildingsEconomicUnitsSuccess
   */
  getBuildingsEconomicUnitsSuccess: (payload) => ({
    type: actions.GET_BUILDINGS_ECONOMIC_UNITS_SUCCESS,
    payload
  }),

  /**
   * Action used for fetching the housing stocks used in the buildings filters.
   * @memberof actions
   * @alias getBuildingsHousingStocks
   */
  getBuildingsHousingStocks: () => ({
    type: actions.GET_BUILDINGS_HOUSING_STOCKS
  }),

  getBuildingsHousingStocksError: () => ({
    type: actions.GET_BUILDINGS_HOUSING_STOCKS_ERROR
  }),

  /**
   * Provides the list of housing stocks used as options in the buildings filters to the reducer.
   * @param {Array} payload - The list of housing stocks
   * @memberof actions
   * @alias getBuildingsHousingStocksSuccess
   */
  getBuildingsHousingStocksSuccess: (payload) => ({
    type: actions.GET_BUILDINGS_HOUSING_STOCKS_SUCCESS,
    payload
  }),

  clearSingleBuilding: () => ({
    type: actions.CLEAR_SINGLE_BUILDING
  }),

  /**
   * Using the normal loadPolicies API call for fetching policies
   * */
  getConnectedPolicies: (buildingId) => ({
    type: actions.GET_CONNECTED_POLICIES,
    buildingId
  }),

  getConnectedPoliciesError: () => ({
    type: actions.GET_CONNECTED_POLICIES_ERROR
  }),

  getConnectedPoliciesSuccess: (payload) => ({
    type: actions.GET_CONNECTED_POLICIES_SUCCESS,
    payload
  }),

  clearConnectedPolicies: () => ({
    type: actions.CLEAR_CONNECTED_POLICIES
  }),

  setlastVisitedBuildingId: (buildingId) => ({
    type: actions.SET_LAST_VISITED_BUILDING_ID,
    buildingId
  }),

  resetLastVisitedBuildingId: () => ({
    type: actions.RESET_LAST_VISITED_BUILDING_ID
  })
};

export default actions;
