import { Map } from 'immutable';
import actions from './actions';

const initialState = new Map({
  buildings: [],
  getBuildingsLoading: false,
  getBuildingsError: false,
  building: null,
  getBuildingLoading: false,
  getBuildingError: false,
  allBuildings: [],
  allBuildingsLoading: false,
  allBuildingsError: false,
  updateBuildingLoading: false,
  updateBuildingError: false,
  exportBuildingsLoading: false,
  exportBuildingsError: false,
  deleteBuildingsLoading: false,
  deleteBuildingsError: false,
  buildingsHousingStocks: [],
  buildingsHousingStocksLoading: false,
  buildingsHousingStocksError: false,
  buildingsCompanyCodes: [],
  buildingsCompanyCodesLoading: false,
  buildingsCompanyCodesError: false,
  buildingsEconomicUnits: [],
  buildingsEconomicUnitsLoading: false,
  buildingsEconomicUnitsError: false,
  createBuildingsLoading: false,
  createBuildingsError: false,
  totalBuildings: 0,
  connectedPolicies: [],
  connectedPoliciesLoading: false,
  connectedPoliciesError: false,
  lastVisitedBuildingId: null
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_BUILDINGS:
      return state.set('getBuildingsLoading', true).set('getBuildingsError', false);
    case actions.GET_BUILDINGS_SUCCESS:
      return state
        .set('getBuildingsLoading', false)
        .set('getBuildingsError', false)
        .set('buildings', action.payload)
        .set('totalBuildings', parseInt(action.meta?.total, 10));
    case actions.GET_BUILDINGS_ERROR:
      return state.set('getBuildingsLoading', false).set('getBuildingsError', true);
    case actions.GET_BUILDING:
      return state.set('getBuildingLoading', true).set('getBuildingError', false);
    case actions.GET_BUILDING_SUCCESS:
      return state.set('getBuildingLoading', false).set('getBuildingError', false).set('building', action.payload);
    case actions.GET_BUILDING_ERROR:
      return state.set('getBuildingLoading', false).set('getBuildingError', true);
    case actions.GET_ALL_BUILDINGS:
      return state.set('allBuildingsLoading', true).set('allBuildingsError', false);
    case actions.GET_ALL_BUILDINGS_SUCCESS:
      return state
        .set('allBuildingsLoading', false)
        .set('allBuildingsError', false)
        .set('allBuildings', action.payload);
    case actions.GET_ALL_BUILDINGS_ERROR:
      return state.set('allBuildingsLoading', false).set('allBuildingsError', true);
    case actions.UPDATE_BUILDINGS:
      return state.set('updateBuildingLoading', true).set('updateBuildingError', false);
    case actions.UPDATE_BUILDINGS_SUCCESS:
      return state.set('updateBuildingLoading', false).set('updateBuildingError', false);
    case actions.UPDATE_BUILDINGS_ERROR:
      return state.set('updateBuildingLoading', false).set('updateBuildingError', true);
    case actions.EXPORT_BUILDINGS:
      return state.set('exportBuildingsLoading', true);
    case actions.EXPORT_BUILDINGS_SUCCESS:
      return state.set('exportBuildingsLoading', false);
    case actions.EXPORT_BUILDINGS_ERROR:
      return state.set('exportBuildingsLoading', false).set('exportBuildingsError', true);
    case actions.DELETE_BUILDINGS:
      return state.set('deleteBuildingsLoading', true).set('deleteBuildingsError', false);
    case actions.DELETE_BUILDINGS_ERROR:
      return state.set('deleteBuildingsLoading', false).set('deleteBuildingsError', true);
    case actions.DELETE_BUILDINGS_SUCCESS:
      return state.set('deleteBuildingsLoading', false).set('deleteBuildingsError', false);
    case actions.RESET_BUILDINGS:
      return state.set('buildings', []);
    case actions.RESET_ALL_BUILDINGS:
      return state.set('allBuildings', []);
    case actions.GET_BUILDINGS_HOUSING_STOCKS:
      return state.set('buildingsHousingStocksLoading', true).set('buildingsHousingStocksError', false);
    case actions.GET_BUILDINGS_HOUSING_STOCKS_SUCCESS:
      return state
        .set('buildingsHousingStocksLoading', false)
        .set('buildingsHousingStocksError', false)
        .set('buildingsHousingStocks', action.payload);
    case actions.GET_BUILDINGS_HOUSING_STOCKS_ERROR:
      return state.set('buildingsHousingStocksLoading', false).set('buildingsHousingStocksError', true);
    case actions.GET_BUILDINGS_COMPANY_CODES:
      return state.set('buildingsCompanyCodesLoading', true).set('buildingsCompanyCodesError', false);
    case actions.GET_BUILDINGS_COMPANY_CODES_SUCCESS:
      return state
        .set('buildingsCompanyCodesLoading', false)
        .set('buildingsCompanyCodesError', false)
        .set('buildingsCompanyCodes', action.payload);
    case actions.GET_BUILDINGS_COMPANY_CODES_ERROR:
      return state.set('buildingsCompanyCodesLoading', false).set('buildingsCompanyCodesError', true);
    case actions.GET_BUILDINGS_ECONOMIC_UNITS:
      return state.set('buildingsEconomicUnitsLoading', true).set('buildingsEconomicUnitsError', false);
    case actions.GET_BUILDINGS_ECONOMIC_UNITS_SUCCESS:
      return state
        .set('buildingsEconomicUnitsLoading', false)
        .set('buildingsEconomicUnitsError', false)
        .set('buildingsEconomicUnits', action.payload);
    case actions.GET_BUILDINGS_ECONOMIC_UNITS_ERROR:
      return state.set('buildingsEconomicUnitsLoading', false).set('buildingsEconomicUnitsError', true);
    case actions.CREATE_BUILDINGS:
      return state.set('createBuildingsLoading', true).set('createBuildingsError', false);
    case actions.CREATE_BUILDINGS_SUCCESS:
      return state.set('createBuildingsLoading', false).set('createBuildingsError', false);
    case actions.CREATE_BUILDINGS_ERROR:
      return state.set('createBuildingsLoading', false).set('createBuildingsError', true);
    case actions.CLEAR_SINGLE_BUILDING:
      return state.set('building', null);
    case actions.GET_CONNECTED_POLICIES:
      return state.set('connectedPoliciesLoading', true).set('connectedPoliciesError', false);
    case actions.GET_CONNECTED_POLICIES_SUCCESS:
      return state
        .set('connectedPoliciesLoading', false)
        .set('connectedPoliciesError', false)
        .set('connectedPolicies', action.payload);
    case actions.GET_CONNECTED_POLICIES_ERROR:
      return state.set('connectedPoliciesLoading', false).set('connectedPoliciesError', true);
    case actions.CLEAR_CONNECTED_POLICIES:
      return state.set('connectedPolicies', []);
    case actions.SET_LAST_VISITED_BUILDING_ID:
      return state.set('lastVisitedBuildingId', action.buildingId);
    case actions.RESET_LAST_VISITED_BUILDING_ID:
      return state.set('lastVisitedBuildingId', null);
    default:
      return state;
  }
};

export default reducer;
