const actions = {
  LOAD_KPIS: 'LOAD_KPIS',
  LOAD_KPIS_SUCCESS: 'LOAD_KPIS_SUCCESS',
  LOAD_KPIS_ERROR: 'LOAD_KPIS_ERROR',
  LOAD_PROFITABILITY_KPIS: 'LOAD_PROFITABILITY_KPIS',
  LOAD_PROFITABILITY_KPIS_SUCCESS: 'LOAD_PROFITABILITY_KPIS_SUCCESS',
  LOAD_PROFITABILITY_KPIS_ERROR: 'LOAD_PROFITABILITY_KPIS_ERROR',
  LOAD_PREMIUM_SETTINGS: 'LOAD_PREMIUM_SETTINGS',
  LOAD_PREMIUM_SETTINGS_SUCCESS: 'LOAD_PREMIUM_SETTINGS_SUCCESS',
  LOAD_PREMIUM_SETTINGS_ERROR: 'LOAD_PREMIUM_SETTINGS_ERROR',
  SET_PREMIUM_KPIS: 'SET_PREMIUM_KPIS',
  SET_PREMIUM_KPIS_SUCCESS: 'SET_PREMIUM_KPIS_SUCCESS',
  SET_PREMIUM_KPIS_ERROR: 'SET_PREMIUM_KPIS_ERROR',
  CLEAR_SET_PREMIUM_KPIS_ERROR: 'CLEAR_SET_PREMIUM_KPIS_ERROR',
  getKpis: (params) => ({
    type: actions.LOAD_KPIS,
    params
  }),
  getKpisSuccess: (kpis) => ({
    type: actions.LOAD_KPIS_SUCCESS,
    kpis
  }),
  getKpisError: () => ({
    type: actions.LOAD_KPIS_ERROR
  }),
  getProfitabilityKpis: (params) => ({
    type: actions.LOAD_PROFITABILITY_KPIS,
    params
  }),
  getProfitabilityKpisSuccess: (kpis) => ({
    type: actions.LOAD_PROFITABILITY_KPIS_SUCCESS,
    kpis
  }),
  getProfitabilityKpisError: () => ({
    type: actions.LOAD_PROFITABILITY_KPIS_ERROR
  }),
  getPremiumSettings: () => ({
    type: actions.LOAD_PREMIUM_SETTINGS
  }),
  getPremiumSettingsSuccess: (premiumSettings) => ({
    type: actions.LOAD_PREMIUM_SETTINGS_SUCCESS,
    premiumSettings
  }),
  getPremiumSettingsError: () => ({
    type: actions.LOAD_PREMIUM_SETTINGS_ERROR
  }),
  setPremiumKpis: (params, queryParams) => ({
    type: actions.SET_PREMIUM_KPIS,
    params,
    queryParams
  }),
  setPremiumKpisSuccess: (kpis) => ({
    type: actions.SET_PREMIUM_KPIS_SUCCESS,
    kpis
  }),
  setPremiumKpisError: () => ({
    type: actions.SET_PREMIUM_KPIS_ERROR
  }),
  clearSetPremiumKpisError: () => ({
    type: actions.CLEAR_SET_PREMIUM_KPIS_ERROR
  })
};
export default actions;
